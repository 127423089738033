import React from "react";
import "./style.css"

  export default function Work() {
    return(
      <div className="work">
        <ul className="worklist">
          <li id="workItem"><a href="https://sad-hermann-405b8a.netlify.app/" className="coffee" id="workText" target={"blank"} >My Coffee Marbella</a></li>
          <li id="workItem"><a href="https://made4living.co.uk/" target={"blank"} className="made" id="workText">Made4Living - Design and property</a></li>
          <li id="workItem"><a href="https://inspiring-bassi-9062d6.netlify.app/" className="designbyruso" target={"blank"} id="workText" >Design by Russo Portfolio</a></li>
          {/* <li><a href="https://inspiring-bassi-9062d6.netlify.app/" className="list" target={"blank"}>Weather App</a></li> */}
          <li id="workItem"><a href="https://russodelsur.github.io/Periodic-table/" className="periodic" target={"blank"} id="workText">Periodic table</a></li>
          <li id="workItem"><a href="https://github.com/russodelsur/Employees-platform" className="periodic" target={"blank"} id="workText">Employees platform - OPP exercise</a></li>
          <li id="workItem"><a href="https://russodelsur.github.io/Comment-Box-project/" className="periodic" target={"blank"} id="workText">Comment box - FAC exercise</a></li>
          <li id="workItem"><a href="https://inspiring-bassi-9062d6.netlify.app/web-design-branding-nforma" className="forma" target={"blank"} id="workText">NForma</a></li>
        </ul>
      </div>
    );
}