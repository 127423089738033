import React from "react";
import "./style.css"
import  "../components/Three/Three";

  export default function Home() {
    return(
      <div className="home">
          <h1>Ruslan Sanakoev</h1>
          <h2 className="intro-title">Designer and developer</h2>
      </div>
    );
}