import React from "react";
import "./style.css"

  export default function About() {
    return(
      <div id="aboutDiv" className="aboutDiv">
          <h1>My name is Ruslan Sanakoev</h1>
          <h2>I am a creative developer and designer from Spain. </h2>
          <h2>I use the MERN stack to create web applications. </h2>
          <h2>I use Javascript, Typescript and Three.js to create interactive experiences.</h2>
          <h2>I am based in London.</h2>
      </div>
    );
}