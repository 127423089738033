import * as THREE from 'three';
import "./Three.css";
import { gsap } from 'gsap/all';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
// import Stats from 'three/examples/jsm/libs/stats.module'

window.onload = function(){

let camera, scene, renderer, spotLight, hemiLight, exposure;
// let stats;
// // ref for lumens: http://www.power-sure.com/lumens.htm
//   '110000 lm (1000W)': 110000,
//   '3500 lm (300W)': 3500,
//   '1700 lm (100W)': 1700,
//   '800 lm (60W)': 800,
//   '400 lm (40W)': 400,
//   '180 lm (25W)': 180,
//   '20 lm (4W)': 20,
//   'Off': 0


// // ref for solar irradiances: https://en.wikipedia.org/wiki/Lux
//   '0.0001 lx (Moonless Night)': 0.0001,
//   '0.002 lx (Night Airglow)': 0.002,
//   '0.5 lx (Full Moon)': 0.5,
//   '3.4 lx (City Twilight)': 3.4,
//   '50 lx (Living Room)': 50,
//   '100 lx (Very Overcast)': 100,
//   '350 lx (Office Room)': 350,
//   '400 lx (Sunrise/Sunset)': 400,
//   '1000 lx (Overcast)': 1000,
//   '18000 lx (Daylight)': 18000,
//   '50000 lx (Direct Sun)': 50000


init();
animate();

function init() {
scene = new THREE.Scene();

const hdrTextureURL = new URL("../img/studio.hdr", import.meta.url);
const loadingManager = new THREE.LoadingManager();

let FOV
let FAR
let NEAR = .3

// Mobile camera
if (window.innerWidth <= 768) {
  FOV = 50
  FAR = 6
  // 769px - 1080px screen width camera
} else if (window.innerWidth >= 769 && window.innerWidth <= 1080) {
  FOV = 50
  FAR = 6
  // > 1080px screen width res camera
} else {
  FOV = 45
  FAR = 6
}

camera = new THREE.PerspectiveCamera(
  FOV,
  window.innerWidth / window.innerHeight,
  NEAR,
  FAR
)

// camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, .01, 20 );
let pixelRatio = window.devicePixelRatio
let AA = true
if (pixelRatio > 1) {
  AA = false
}
const canvas = document.createElement("canvas");
renderer = new THREE.WebGLRenderer({ 
  canvas,
  alpha: false, 
  antialias: AA,
  powerPreference: "high-performance",
  precision: "highp",
});
renderer.physicallyCorrectLights = true;
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.outputEncoding = THREE.sRGBEncoding;
renderer.toneMapping = THREE.ReinhardToneMapping;
exposure = 1.2;
renderer.toneMappingExposure = exposure;
renderer.setPixelRatio( window.devicePixelRatio );
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

// const controls = new OrbitControls( camera, renderer.domElement );

window.addEventListener("resize", onWindowResize, false);

function onWindowResize(){
  let width = window.innerWidth;
  let height = window.innerHeight;
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  renderer.setSize(width, height);
}
// controls.minDistance = 5;
// controls.maxDistance = 20;
// controls.update();

function moveCamera (x, y, z) {
  gsap.to(camera.position,    {
    x,
    y, 
    z, 
    duration: 5
});}

function rotateCamera (x, y, z){
gsap.to(camera.rotation, {
  x,
  y,
  z,
  duration: 5
});}

document.getElementById("work").addEventListener("click", function(){
  moveCamera(1.705767645472689, .92, 0.8242832549970878);
  rotateCamera(0, -1.8, 0);
});
  document.getElementById("rs").addEventListener("click", function(){
    moveCamera(-.2, 1.500, 4);
    rotateCamera(-.2, 0, 0);
});
document.getElementById("about").addEventListener("click", function(){
  moveCamera(-.200, 1.700, 0);
  rotateCamera(0, 0, 0);
});
document.getElementById("designwork").addEventListener("click", function(){
  moveCamera(-1, 1.300, 1.5);
  rotateCamera(0, 1.5, 0);
});

// Materials
const textureLoader = new THREE.TextureLoader(loadingManager);
//Floor maps
const mapFloor = new URL("../img/AChevron.jpg", import.meta.url);
const bumpFloor = new URL("../img/AChevron_bump.jpg", import.meta.url);

// Arch maps
const mapArch = new URL("../img/Ash.jpg", import.meta.url);
const bumpArch = new URL("../img/Ash_bump.jpg", import.meta.url);
let rotate = (Math.PI);

// walnut maps
const mapWalnut = new URL("../img/Walnut-Architextures.jpg", import.meta.url);
const bumpWalnut = new URL("../img/Walnut_bump.jpg", import.meta.url);

// Work Logos
const coffee = new URL("../img/mycoffee.jpg", import.meta.url);
const nforma = new URL("../img/nforma.png", import.meta.url);
const byrusso = new URL("../img/byrusso.jpg", import.meta.url);
const madeFor = new URL("../img/logo M4L-04.png", import.meta.url);
const hyperionDev = new URL("../img/HyperionDev.png", import.meta.url);

//artworks
const mapMarbella = new URL("../img/Marbella.jpg", import.meta.url);
const mapArt = new URL("../img/arch.jpg", import.meta.url);
const mapLA = new URL("../img/la.jpg", import.meta.url);

let floorMat = new THREE.MeshPhongMaterial( {
  // roughness: 0.7,
  color: 0xffffff,
  // metalness: 0.2,
  bumpScale: 0.05,
} );

let wallMat = new THREE.MeshLambertMaterial( {
  // roughness: 0.6,
  color: 0xe3d9c6,

} );
let ash = new THREE.MeshPhongMaterial( {
  // roughness: 0.6,
  color: 0xffffff,
  // metalness: 0.2,
  bumpScale: 0.05,
} );

  let walnut = new THREE.MeshPhongMaterial( {
    // roughness: 0.6,
    // metalness: .2,
    color: 0xffffff,
    bumpScale: 0.001,
  } );

  let marbella = new THREE.MeshLambertMaterial( {
    // roughness: .2,
    // metalness: 0,
    color: 0xffffff,
  } );

  let la = new THREE.MeshLambertMaterial( {
    // roughness: .2,
    // metalness: 0,
    color: 0xffffff,
  } );

  let artworkArch = new THREE.MeshLambertMaterial( {
    // roughness: .2,
    // metalness: 0.4,
    color: 0xffffff,
  } );

  //Arch
  textureLoader.load( mapArch, function ( map ) {
    map.wrapS = THREE.RepeatWrapping;
    map.wrapT = THREE.RepeatWrapping;
    map.anisotropy = 4;
    map.repeat.set(  15,15  );
    map.rotation =rotate;
    map.encoding = THREE.sRGBEncoding;
    ash.map = map;
    ash.needsUpdate = true;
  } );
  
  textureLoader.load( bumpArch, function ( map ) {
    map.wrapS = THREE.RepeatWrapping;
    map.wrapT = THREE.RepeatWrapping;
    map.anisotropy = 4;
    map.repeat.set(  15,15  );
    map.rotation =rotate;
    ash.bumpMap = map;
    ash.needsUpdate = true;
  } );


// Floor
textureLoader.load( mapFloor, function ( map ) {
  map.wrapS = THREE.RepeatWrapping;
  map.wrapT = THREE.RepeatWrapping;
  map.anisotropy = 4;
  map.repeat.set(  4, 4  );
  map.encoding = THREE.sRGBEncoding;
  floorMat.map = map;
  floorMat.needsUpdate = true;
} );

textureLoader.load( bumpFloor, function ( map ) {
  map.wrapS = THREE.RepeatWrapping;
  map.wrapT = THREE.RepeatWrapping;
  map.anisotropy = 4;
  map.repeat.set(  4, 4  );
  floorMat.bumpMap = map;
  floorMat.needsUpdate = true;
} );

// Walnut
textureLoader.load( mapWalnut, function ( map ) {
  map.wrapS = THREE.RepeatWrapping;
  map.wrapT = THREE.RepeatWrapping;
  map.anisotropy = 4;
  map.rotation =rotate;
  map.repeat.set(  5, 5  );
  map.encoding = THREE.sRGBEncoding;
  walnut.map = map;
  walnut.needsUpdate = true;
} );

//Wall

textureLoader.load( bumpWalnut, function ( map ) {
map.wrapS = THREE.RepeatWrapping;
map.wrapT = THREE.RepeatWrapping;
map.anisotropy = 4;
map.rotation =rotate;
map.repeat.set(  5, 5  );
walnut.bumpMap = map;
walnut.needsUpdate = true;
} );

// Marbella
 textureLoader.load( mapMarbella, function ( map ) {
  map.wrapS = THREE.ClampToEdgeWrapping;
  map.wrapT = THREE.MirroredRepeatWrapping;
  map.anisotropy = 4;
  map.offset.y = 1;
  map.repeat.set(  1, 1  );
  map.encoding = THREE.sRGBEncoding;
  marbella.map = map;
  marbella.needsUpdate = true;
} );

// LA
textureLoader.load( mapLA, function ( map ) {
  map.wrapS = THREE.ClampToEdgeWrapping;
  map.wrapT = THREE.MirroredRepeatWrapping;
  map.anisotropy = 4;
  map.anisotropy = 4;
  map.offset.y = 1;
  map.repeat.set(  1, 1  );
  map.encoding = THREE.sRGBEncoding;
  la.map = map;
  la.needsUpdate = true;
} );

// Arch
textureLoader.load( mapArt, function ( map ) {
  map.wrapS = THREE.ClampToEdgeWrapping;
  map.wrapT = THREE.MirroredRepeatWrapping;
  map.anisotropy = 4;
  map.anisotropy = 4;
  map.offset.y = 1;
  map.repeat.set(  1, 1  );
  map.encoding = THREE.sRGBEncoding;
  artworkArch.map = map;
  artworkArch.needsUpdate = true;
} );

let screenMat = new THREE.MeshLambertMaterial( {
  // roughness: 1,
  color: 0xffffff,
  // metalness: 1,
} );

const paint = new THREE.MeshLambertMaterial({
  // roughness: .5,
  // metalness: .2,
  color: 0xb0b0a9,
  // clearcoat: 1,
  // clearcoatRoughness: .2,
})	

const white = new THREE.MeshLambertMaterial({
  color: 0xf1f0ea,
})

const black = new THREE.MeshLambertMaterial({
  color: 0x0e0e10,
})

const metal = new THREE.MeshStandardMaterial({
  roughness: .2,
  metalness: .8,
  color: 0x2b2b2c	,
})

function waitForElm(selector) {
  return new Promise(resolve => {
      if (document.querySelector(selector)) {
          return resolve(document.querySelector(selector));
      }
      const observer = new MutationObserver(mutations => {
          if (document.querySelector(selector)) {
              resolve(document.querySelector(selector));
              observer.disconnect();
          }
      });
      observer.observe(document.body, {
          childList: true,
          subtree: true
      });
  });
}
waitForElm('.coffee').then((elm) => {
  elm.addEventListener("mouseover", function(){ 
  textureLoader.load( coffee, function ( map ) {
    map.anisotropy = 4;
    map.repeat.set(  2 ,2  );
    map.encoding = THREE.sRGBEncoding;
    map.offset.x = -4/16;
    screenMat.map = map;
    screenMat.color = 0x000000;
    screenMat.needsUpdate = true;
  });
  })
});
waitForElm('.made').then((elm) => {
  elm.addEventListener("mouseover", function(){ 
  textureLoader.load( madeFor, function ( map ) {
    map.anisotropy = 4;
    map.repeat.set(  12/8 ,12  );
    map.offset.x = -1/16;
    map.offset.y = -35/16;
    map.encoding = THREE.sRGBEncoding;
    screenMat.map = map;
    screenMat.needsUpdate = true;
  });
  })
});

waitForElm('.forma').then((elm) => {
  elm.addEventListener("mouseover", function(){ 
  textureLoader.load( nforma, function ( map ) {
    map.anisotropy = 4;
    map.repeat.set(  2 ,2  );
    map.encoding = THREE.sRGBEncoding;
    map.offset.x = -4/16;
    screenMat.map = map;
    screenMat.needsUpdate = true;
  });
  })
});

waitForElm('.designbyruso').then((elm) => {
  elm.addEventListener("mouseover", function(){ 
  textureLoader.load( byrusso, function ( map ) {
    map.anisotropy = 4;
    map.repeat.set(  2 ,2/1.3  );
    map.encoding = THREE.sRGBEncoding;
    map.offset.x = -4/16;
    map.offset.y = 3.5/16;
    screenMat.map = map;
    screenMat.needsUpdate = true;
  });
  })
});

waitForElm('.periodic').then((elm) => {
  elm.addEventListener("mouseover", function(){ 
  textureLoader.load( hyperionDev, function ( map ) {
    map.anisotropy = 4;
    map.repeat.set(  7/4 ,7 );
    map.encoding = THREE.sRGBEncoding;
    map.offset.x = -3/16;
    map.offset.y = -15/16;
    screenMat.map = map;
    screenMat.needsUpdate = true;
  });
  })
});

// window.addEventListener("mouseover", function() {
//   console.log(camera.position);
// })

camera.position.set(-.2, 1.500, 4);
rotateCamera(-.2,0,0);

// Loading models
let decoderPath = 'https://www.gstatic.com/draco/versioned/decoders/1.4.1/';
const loader = new GLTFLoader(loadingManager);
const draco = new DRACOLoader();
// draco.setDecoderConfig({type: 'js'});
draco.setDecoderPath(decoderPath);
loader.setDRACOLoader( draco );

loader.load( './gltf/arch.gltf', function ( gltf ) {		
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = paint;
  });
  gltf.scene.traverse((x) => {
    if (x.isMesh) {
    x.castShadow = true;
    x.receiveShadow = true;
    x.geometry.computeVertexNormals();
    }
  });
  scene.add( gltf.scene );
	},
);	

loader.load( './gltf/screen.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = screenMat;
  });
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
    o.castShadow = true;
    o.receiveShadow = true;
    }
  });
  scene.add( gltf.scene );
	},
);

loader.load( './gltf/chairblack.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = black;
    o.castShadow = true;
    o.receiveShadow = true;
    gltf.frustumCulled = false;
    });
    let chair = gltf.scene.clone();
    chair.rotation.y = 4;
    chair.position.z = -.2;
    chair.position.x = -.2;
    scene.add( chair );
  scene.add( gltf.scene );
	},
);

loader.load( './gltf/chairwhite.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = white;
    o.castShadow = true;
    o.receiveShadow = true;
    gltf.frustumCulled = false;
    });
    let chair = gltf.scene.clone();
    chair.rotation.y = 4;
    chair.position.z = -.2;
    chair.position.x = -.2;
    scene.add( chair );
  scene.add( gltf.scene );
	},
);

function glbLoad( gltf) {
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = white;
  });		
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
    o.castShadow = true;
    o.receiveShadow = true;
    }
  });
  scene.add( gltf.scene );
}

loader.load('./gltf/white.gltf', glbLoad)

loader.load( './gltf/wall.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = wallMat;
    o.castShadow = true;
    o.receiveShadow = true;
    gltf.frustumCulled = false;
    });
  scene.add( gltf.scene );
	},
);

loader.load( './gltf/archArt.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = artworkArch;
  });
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
    o.castShadow = true;
    o.receiveShadow = true;
    }
  });
  scene.add( gltf.scene );
	},
);

loader.load( './gltf/la.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = la;
  });
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
    o.castShadow = true;
    o.receiveShadow = true;
    }
  });
  scene.add( gltf.scene );
	},
);

loader.load( './gltf/marbella.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = marbella;
  });
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
    o.castShadow = true;
    o.receiveShadow = true;
    }
  });
  scene.add( gltf.scene );
	},
);

loader.load( './gltf/black.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = black;
  });
  gltf.scene.traverse((x) => {
    if (x.isMesh) {
    x.castShadow = true;
    x.receiveShadow = true;
    x.geometry.computeVertexNormals();
    }
  });
  scene.add( gltf.scene );
	},
);	

loader.load( './gltf/floor.gltf', function ( gltf ) {			
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
      o.material = floorMat;
  }
  });
  gltf.scene.traverse((o) => {
    if (o.isMesh) {
    o.castShadow = true;
    o.receiveShadow = true;
    }
  });
  scene.add( gltf.scene );
	},
);	

loader.load( './gltf/table.gltf', function ( gltf ) {		
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = walnut;
  });
  gltf.scene.traverse((x) => {
    if (x.isMesh) {
    x.castShadow = true;
    x.receiveShadow = true;
    x.geometry.computeVertexNormals();
    }
  });
	scene.add( gltf.scene );
	},
);	

loader.load( './gltf/metal.gltf', function ( gltf ) {		
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = metal;
    gltf.frustumCulled = false;
  });
  gltf.scene.traverse((x) => {
    if (x.isMesh) {
    x.castShadow = true;
    x.receiveShadow = true;
    x.geometry.computeVertexNormals();
    }
  });
	scene.add( gltf.scene );
	},
);

loader.load( './gltf/flooredge.gltf', function ( gltf ) {		
  gltf.scene.traverse((o) => {
    if (o.isMesh) o.material = ash;
  });
  gltf.scene.traverse((x) => {
    if (x.isMesh) {
    x.castShadow = true;
    x.receiveShadow = true;
    x.geometry.computeVertexNormals();
    }
  });
	scene.add( gltf.scene );
	},
);

// const geometry = new THREE.BoxGeometry( .8, .8, .1 );
// const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
// const cube = new THREE.Mesh( geometry, material );
// cube.position.set(-.195, 1.7, -1.55)
// scene.add( cube );

// const parent = document.getElementsByClassName("home");


const loaderHDR = new RGBELoader(loadingManager);
loaderHDR.load(hdrTextureURL, function(texture){
  texture.mapping = THREE.EquirectangularReflectionMapping;
  scene.background = texture;
  // scene.environment = texture;
});

//  Load Light

const ambientLight = new THREE.AmbientLight( 0xffffff, .6 );
scene.add( ambientLight );

hemiLight = new THREE.HemisphereLight(
  0xffeeb1, // bright sky color
  0x000000, // dim ground color
  1 // intensity
);
scene.add( hemiLight );
			
const directionalLight = new THREE.DirectionalLight( 0xfff5b6, 10 );
directionalLight.position.set( 5.000, 6.000, -1.00 );
directionalLight.shadow.bias = -0.001;
directionalLight.target.position.set(0, 0, 0);
directionalLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
const frustumSize = 10;
directionalLight.shadow.camera = new THREE.OrthographicCamera(-frustumSize / 2, frustumSize / 2, frustumSize/2 , -frustumSize/2 , .100, 10.000);
directionalLight.shadow.camera.far = 20.000;
directionalLight.castShadow = true;
scene.add( directionalLight );	

spotLight = new THREE.SpotLight(0xffa95c, 3000);
spotLight.position.set( -1.000, 3.400, 0 );
spotLight.target.position.set(0, 0, -1.000);
spotLight.angle = Math.PI/2.5;
spotLight.penumbra = .5;
spotLight.power = 20;
spotLight.decay = 1;
spotLight.shadow.bias = -0.001;
spotLight.shadow.mapSize = new THREE.Vector2(1024 , 1024 );
spotLight.shadow.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 10 );
spotLight.castShadow = true;
scene.add(spotLight);


  const light = spotLight.clone();
  light.position.set(-2, 3.4, 1.5);
  light.target.position.set(-2, 0, 0);
  scene.add(light);

  const light2 = spotLight.clone();
  light2.position.set(2, 3.4, 1.5);
  light2.target.position.set(2, 0, 0);
  scene.add(light2);
  //Light helper
  // let spotLightHelper = new THREE.SpotLightHelper(light);
  // scene.add(spotLightHelper);
  // let cameraHelper = new THREE.CameraHelper(light.shadow.camera);
  // scene.add(cameraHelper)

//Light helper

// let spotLightHelper = new THREE.SpotLightHelper(light);
// scene.add(spotLightHelper);
// let cameraHelper = new THREE.CameraHelper(light.shadow.camera);
// scene.add(cameraHelper)

let progressBar, progressContainer;

progressBar = document.getElementById("progress")
progressContainer = document.querySelector(".progress-bar-container")
loadingManager.onProgress = function(url, loaded, total){
  progressBar.value = (loaded/ total)*100;
}
loadingManager.onLoad = function() {
  progressContainer.style.display = "none";
}
// stats = Stats()
// document.body.appendChild(stats.dom);
}

function animate() {
  requestAnimationFrame( animate );
  render();
  // console.log("Scene polycount:", renderer.info.render.triangles)
  // console.log("Active Drawcalls:", renderer.info.render.calls);
  // console.log("Textures in Memory", renderer.info.memory.textures)
  // console.log("Geometries in Memory", renderer.info.memory.geometries)
  // console.log(renderer.info);
  // stats.update()
}

function render() {
  renderer.toneMappingExposure = Math.pow( exposure, 5.0 ); // to allow for very bright scenes.
  // const time = Date.now() * 0.0005;
  // bulbLight.position.y = Math.cos( time ) * 0.75 + 1.25;
  renderer.render( scene, camera );
}

}